.navBar {
    /* background-color: #0900ab; */
    font-family: sans-serif;
    line-height: 30px;
}

.link {
    text-decoration: none;
    color: white;
}

.link:hover,
.link:focus {
    color: #B3B3B3;
}

a:hover {
    text-decoration: none;
}