/* .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.1);
  } */

  .iconoIMG {
    width: 6%;
    height: 12%;
  }

  @media (max-width: 768px) {
    table {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

/*     tr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        border: none;
    }
*/
    td {
        text-align: center;
    } 
}

  .media .ico {
    width: 40px;
    height: 40px;
  }