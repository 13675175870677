body {
}

.bodyCard {
    background-color: rgb(247, 247, 247);
}

.login {
    display: flex;
    align-items: center;
}

.border-none{
    border: none;
}

.principal{
    min-height: calc(100vh - 60px - 82px /* - 70px */);
}

.fondo {
    background-color: #00023d;
    color: white;
    height: 60px;
}

header {
    background-color: #2357ff;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.imgHome{
    width: 100%;
    height: 100%;

}

@media (max-width: 768px) { 
    .imgHome {
        width: 100%;
        height: auto;
        transform: translateY(+30%);
    }
}

  /* Estilos para el pie de página */
/*   footer {
    background-color: #232f34;
    color: #fff;
    padding: 20px;
    text-align: center;
  } */

.mybg-success {
    background-color: #00963E;
    border-color: #00963E;
}

.mybtn-success {
    background-color: #00963E;
    border-color: #00963E;
}



