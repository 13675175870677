/* Estilos generales para la vista de lista y la tabla en pantallas más grandes (PC) */
.proceso-list,
.table-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.table {
  width: 100%; /* Ajusta el ancho de la tabla según tus necesidades */
  /* Estilos de la tabla aquí */
}

.proceso-item {
  width: 30%; /* Ajusta el ancho de cada elemento de proceso según tus necesidades */
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.proceso-info1 {
  margin-bottom: 5px;
}

.proceso-info2 {
    display: flex;
    justify-content: space-between;
    /* Otras propiedades de estilo para .proceso-info */
  }


  .proceso-info3 {
    margin-bottom: 5px;
    text-align: center
  }

.proceso-actions {
  display: flex;
  justify-content: center;
}

.btn {
  margin: 2px;
}

@media (min-width: 769px) {
  .proceso-list {
      display: none;
  }
}

/* Ocultar la tabla en pantallas pequeñas (celulares) */
@media (max-width: 768px) {
  .table2 {
    display: none;
  }
  
  .proceso-list,
  .proceso-item {
    width: 100%; /* Ocupa todo el ancho en dispositivos móviles */
  }

  /* Puedes aplicar estilos adicionales para dispositivos móviles aquí */
}